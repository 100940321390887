// src/components/LoginForm.tsx
import React, { useState } from 'react';
import {login} from "../services/AuthServices";
import {ProgressSpinner} from "primereact/progressspinner";
import './Login.css';

interface LoginFormProps {
    onLoginSuccess: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLoginSuccess }) => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [authenticating, setAuthenticating] = useState<boolean>(false);
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            setAuthenticating(true);
            await login({ username, password });
            onLoginSuccess();
            setAuthenticating(false);
        } catch (err:any) {
            setError(err.message);
            setAuthenticating(false);
        }
    };

    return (
        <>
            <div>
        <form onSubmit={handleSubmit}>
            <div className={'text_area'}>
                <label>Utente:</label>
                <input
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    className="text_input"
                />
            </div>
            <div className={'text_area'}>
                <label>Password:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="text_input"
                />
            </div>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <button type="submit" className={'btn'}>Login</button>
        </form>
            {authenticating? <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />:null }
            </div>
        </>
    );
};

export default LoginForm;
