import {ListaProdottoTreno, ListaTreniWC, Treno} from "../models/Treni";
import config from "../config";
import {AuthService} from "./Login";

export class TrainService {
    private apiUrl: string;
    private token: string|null;
    private authService: AuthService;

    constructor() {
        this.authService = new AuthService(config.REFRESH_URL);
        this.apiUrl = config.API_URL + '/treno';
        this.token = sessionStorage.getItem('token');
    }

    private getToken(): string | null {
        return sessionStorage.getItem('token');
    }

    public async importTreno(id:number): Promise<any> {
        this.apiUrl = this.apiUrl + `/${id}/import/`;
        console.log(this.apiUrl);
        console.log(id)
        if (!this.token) {
            await this.authService.refreshToken();
            this.token = this.getToken();
        }

        const response = await fetch(this.apiUrl, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "id":id }),
        });

        if (!response.ok) {
            if(response.status === 401) {
                await this.authService.refreshToken();
                this.token = this.getToken();
                const response = await fetch(this.apiUrl, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${this.token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ "id":id }),
                });
            } else {
                throw new Error(`Error fetching trains: ${response.statusText}`);
            }

        }


        const data  = await response.json();
        return data;
    }

    public async getTreni(): Promise<Treno[]> {



        if (!this.token) {
            await this.authService.refreshToken();
            this.token = this.getToken();
        }

        const response = await fetch(this.apiUrl, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.token}`,
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching trains: ${response.statusText}`);
        }

        if(response.status === 401) {
            await this.authService.refreshToken();
            const response = await fetch(this.apiUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-Type': 'application/json',
                },
            });
        }

        const data: Treno[] = await response.json();
        return data;
    }

    public async fetchProductsByType(productType: string):Promise<ListaProdottoTreno>  {
        const baseUrl = config.FERROVIE_WC + '/products';

        const url = new URL(baseUrl);
        url.searchParams.append('consumer_key', config.CONSUMER_KEY);
        url.searchParams.append('consumer_secret', config.CONSUMER_SECRET);
        url.searchParams.append('type', productType);

        const response = await fetch(url.toString(), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`Error fetching products: ${response.statusText}`);
        }

        const products = await response.json();
        return products;
    };

}

export class ListTrainServiceWC {
    private apiUrl: string;


    constructor() {
        this.apiUrl = config.FERROVIE_WP + 'trains' + `?consumer_key=${config.CONSUMER_KEY}&consumer_secret=${config.CONSUMER_SECRET}`;

    }

    public async getTreni(): Promise<ListaTreniWC> {
        const response = await fetch(this.apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching trains: ${response.statusText}`);
        }

        const data: ListaTreniWC = await response.json();
        return data;
    }
}

export class TrainServiceWC {
    private apiUrl: string;

    constructor(id:any) {
        this.apiUrl = config.FERROVIE_WC + '/trains' + `/${id}` + `?consumer_key=${config.CONSUMER_KEY}&consumer_secret=${config.CONSUMER_SECRET}`;
    }

    public async getTreni(): Promise<Treno[]> {
        const response = await fetch(this.apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching trains: ${response.statusText}`);
        }

        const data: Treno[] = await response.json();
        return data;
    }
}

