import React, {useEffect, useRef, useState} from "react"
import {Alignment, Icon, Navbar} from "@blueprintjs/core";
import {Button as ButtonNav} from "@blueprintjs/core/lib/esm/components/button/buttons";
import {Panel} from "primereact/panel";
import {useNavigate} from "react-router-dom";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Prodotto} from "../../models/Prodotto";
import {ProdottoView} from "./prodottoView";
import {Toast} from "primereact/toast";
import axios, {Axios} from "axios";
import config from "../../config";
import {prodottiServices} from "../../services/ProdottoServices";
import {DeferredContent} from "primereact/deferredcontent";
import { ProgressBar } from "primereact/progressbar";

let dataTable:Prodotto[] = []

export const ProdottiPanel =  ()=>{
    const navigate = useNavigate();
    const toastAction = useRef(null);
    const [, setProducts] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState<Prodotto | null>(null);
    const toast = useRef(null);
    const[dataTable, setDataTable] = useState<Prodotto[] | any>([]);

    let token = sessionStorage.getItem('token')

    const onDataLoad = async () => {
        axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
        try {
            const response = await axios.get<Prodotto[]>(`${config.API_URL}/prodotti`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + sessionStorage.getItem('token')
                },
            });
            console.log(response.data);
            setDataTable(response.data);
        } catch (err) {
            if (axios.isAxiosError(err) && err.response) {
                // Handle known error responses from the server
                //setError(`Error: ${err.response.status} - ${err.response.statusText}`);
            } else {
                // Handle unexpected errors
                //setError('An unexpected error occurred');
            }
        }
    };

    useEffect(() => {


    }, []);

    const saveAction = () => {
        // @ts-ignore
        toastAction.current.show({ severity: 'success', summary: 'Save', detail: 'Prodotto Salvato' });
    }

    const refreshAction = () => {
        // @ts-ignore
        toastAction.current.show({ severity: 'info', summary: 'Refresh', detail: 'Caricamento Prodotti terminato' });
    }

    const deleteAction = () => {
        // @ts-ignore
        toastAction.current.show({ severity: 'success', summary: 'Cancellazione', detail: 'Cancellazione prodotto fatto' });
    }

    return (
        <>
            <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading>Prenotazioni</Navbar.Heading>
                    <Navbar.Divider />
                    <ButtonNav className="bp5-minimal" icon="document" text="Ordini" onClick={()=>{navigate(`/prenotazioni/ordini`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Prodotti" onClick={()=>{navigate(`/prenotazioni/prodotti`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Treni" onClick={()=>{navigate(`/prenotazioni/treni`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Servizi" onClick={()=>{navigate(`/prenotazioni/servizi`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Pacchetti" onClick={()=>{navigate(`/prenotazioni/pacchetti`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Biglietti" onClick={()=>{navigate(`/prenotazioni/biglietti`)}}/>
                </Navbar.Group>
                {selectedOrder?
                <Navbar.Group align={Alignment.RIGHT}>

                    <ButtonNav className="bp5-minimal" icon="refresh" text="Ricarica" onClick={()=>{refreshAction()}}/>
                    <ButtonNav className="bp5-minimal" icon="delete" text="Cancella" onClick={()=>{deleteAction()}}/>

                </Navbar.Group>
                    : null }
            </Navbar>
            <Panel header={"Prodotti Disponibili"} style={{margin:"1em"}}>
                {dataTable?<>
                <DeferredContent onLoad={onDataLoad}>
                <DataTable value={dataTable} selectionMode="single" showGridlines={true} tableStyle={{ minWidth: '70rem' }} selection={selectedOrder}
                           onSelectionChange={(e) => setSelectedOrder(e.value as Prodotto)} dataKey="id">

                    <Column field="product_name" header="Prodotto"></Column>
                    <Column field="identity" header="Code"></Column>
                    <Column field="price" header="Prezzo"></Column>
                    <Column field="costo" header="Costo"></Column>
                    <Column body={(prodotto)=>{
                        let label:any = '';
                        if(prodotto.type == '0') {
                            label = <Icon icon="train" />
                        } else {
                            label = <Icon icon="barcode" />
                        }
                        return(
                            <>{label}</>
                        )
                    }} header="Type"></Column>
                </DataTable>
                </DeferredContent></>:<ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
            </Panel>
            <Toast ref={toastAction} />
            {selectedOrder ? <ProdottoView  imported={selectedOrder.imported} id={selectedOrder.id} product_name={selectedOrder.product_name} identity={selectedOrder.identity} type={selectedOrder.type} data_product={selectedOrder.data_product} price={selectedOrder.price} costo={selectedOrder.price} train={selectedOrder.train} trabocco={selectedOrder.trabocco} other={selectedOrder.other} /> :null}
        </>
    )
}