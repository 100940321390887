import {InputText} from "primereact/inputtext";


export const ManagerImportFerrovie = () =>{

    return (
        <div className="card flex justify-content-center">
            <label htmlFor="username">Numero Ordine Ferrovia</label>
            <InputText id="username" aria-describedby="username-help"/>
            <small id="username-help">
                Enter your username to reset your password.
            </small>
        </div>
    )
}

