import {Panel} from "primereact/panel";
import {Alignment, Button, Navbar} from "@blueprintjs/core";
import React from "react";
import {useNavigate} from "react-router-dom";

export const FattureEmesse = ()=>{
    const navigate = useNavigate();
    return (
        <>
            <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading>Fatture</Navbar.Heading>
                    <Navbar.Divider />
                    <Button className="bp5-minimal" icon="document" text="Ordini"  onClick={()=>{navigate('/fatture/ordini')}}/>
                    <Button className="bp5-minimal" icon="document" text="Fatture Emesse"  onClick={()=>{navigate('/fatture/emesse')}}/>
                    <Button className="bp5-minimal" icon="document" text="Corrispettivi"  onClick={()=>{navigate('/fatture/corrispettivi')}}/>
                    <Button className="bp5-minimal" icon="document" text="Rimborsi"  onClick={()=>{navigate('/fatture/rimborsi')}}/>

                    <Button className="bp5-minimal" icon="document" text="Transazioni Sumup" onClick={()=>{navigate('/fatture/sumup')}} />
                    <Button className="bp5-minimal" icon="document" text="Transazioni Stripe"  onClick={()=>{navigate('/fatture/stripe')}}/>

                </Navbar.Group>
            </Navbar>

        <Panel header={"Fatture emesse e contabilizzate"} style={{margin:"1em"}}>

        </Panel>
        </>
    )

}