import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom";
import {Alignment, Navbar} from "@blueprintjs/core";
import {Button as ButtonNav} from "@blueprintjs/core/lib/esm/components/button/buttons";
import {Panel} from "primereact/panel";
import {ProgressBar} from "primereact/progressbar";


export const ServiziPanel = ()=>{

    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        setIsLoading(true);
    },[])

    const navigate = useNavigate();

    return (
        <>
            <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading>Prodotti</Navbar.Heading>
                    <Navbar.Divider />
                    <ButtonNav className="bp5-minimal" icon="document" text="Ordini" onClick={()=>{navigate(`/prenotazioni/ordini`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Prodotti" onClick={()=>{navigate(`/prenotazioni/prodotti`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Treni" onClick={()=>{navigate(`/prenotazioni/treni`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Servizi" onClick={()=>{navigate(`/prenotazioni/servizi`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Pacchetti" onClick={()=>{navigate(`/prenotazioni/pacchetti`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Biglietti" onClick={()=>{navigate(`/prenotazioni/biglietti`)}}/>
                </Navbar.Group>
            </Navbar>
            <Panel header={"Servizi"} style={{margin:"1em"}} >
            {isLoading?
                <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                :
                null
            }
            </Panel>
        </>
    )
}