import axios from "axios";
import {Prodotto} from "../models/Prodotto";
import config from "../config";
import {Ordine} from "../pages/prenotazioni/ordini";
import {AuthService} from "./Login";
import {ListaProdottoTreno, Treno} from "../models/Treni";

export const  OrderService = async () => {
    let token = sessionStorage.getItem('token')
    axios.defaults.headers.common = {'Authorization': `Bearer ${token}`}
    try {
        const response = await axios.get<Ordine[]>(`${config.API_URL}/ordine`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
        });
        console.log(response.data);
        return response.data;
    } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
            // Handle known error responses from the server
            //setError(`Error: ${err.response.status} - ${err.response.statusText}`);
        } else {
            // Handle unexpected errors
            //setError('An unexpected error occurred');
        }
    }

};

export class OrdineService {

    private apiUrl: string;
    private token: string|null;
    private authService: AuthService;

    constructor() {
        this.authService = new AuthService(config.REFRESH_URL);
        this.apiUrl = config.API_URL + '/ordine';
        this.token = sessionStorage.getItem('token');
    }

    private getToken(): string | null {
        return sessionStorage.getItem('token');
    }

    public async listaBiglietti():Promise<Ordine[]>  {

        this.apiUrl = this.apiUrl + `/0/lista_biglietti/`;

        if (!this.token) {
            await this.authService.refreshToken();
            this.token = this.getToken();
        }

        //'Authorization': `Bearer ${this.token}`,
        const response = await fetch(this.apiUrl, {
            method: 'GET',
            headers: {

                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching trains: ${response.statusText}`);
        }

        if(response.status === 401) {
            await this.authService.refreshToken();
            //'Authorization': `Bearer ${this.token}`,
            const response = await fetch(this.apiUrl, {
                method: 'GET',
                headers: {

                    'Content-Type': 'application/json',
                },
            });
        }

        let json_return = await response.json();
        const ordini: Ordine[] = json_return?.ordini
        return ordini;

    }

    public async listaPacchetti():Promise<Ordine[]>  {

        this.apiUrl = this.apiUrl + `/0/lista_pacchetti/`;

        if (!this.token) {
            await this.authService.refreshToken();
            this.token = this.getToken();
        }

        //'Authorization': `Bearer ${this.token}`,
        const response = await fetch(this.apiUrl, {
            method: 'GET',
            headers: {

                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching trains: ${response.statusText}`);
        }

        if(response.status === 401) {
            await this.authService.refreshToken();
            //'Authorization': `Bearer ${this.token}`,
            const response = await fetch(this.apiUrl, {
                method: 'GET',
                headers: {

                    'Content-Type': 'application/json',
                },
            });
        }

        let json_return = await response.json();
        const ordini: Ordine[] = json_return?.ordini
        return ordini;

    }

}