import {Panel} from "primereact/panel";
import React, {useEffect, useState} from "react";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {Treni, Treno, DataTrain} from "../../../models/Treni";
import {TrainService} from "../../../services/Treni";
import {ProgressBar} from "primereact/progressbar";



export const GestioneTreni = ()=> {

    const [listaTreni, setListaTreni] = useState<Treni>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const paginatorLeft = <Button type="button" icon="pi pi-refresh" text />;
    const paginatorRight = <Button type="button" icon="pi pi-download" text />;

    useEffect(() => {
        setIsLoading(true);
        const trainService = new TrainService();
        trainService.getTreni()
            .then(treni => {
                setListaTreni(treni)
                setIsLoading(false)
                console.log('Treni:', treni);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);

    return (
        <Panel style={{margin:"1em"}} header={"Tipo Treni Importati"}>
            {isLoading?
            <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>:
            <DataTable value={listaTreni} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
                       paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                       currentPageReportTemplate="{first} to {last} of {totalRecords}" paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}>
                <Column field="id" header="#" style={{ width: '25%' }}></Column>
                <Column field="id_train" header="WPID" style={{ width: '25%' }}></Column>
                <Column field="slug" header="SLUG" style={{ width: '25%' }}></Column>
                <Column field="identity" header="Identity" style={{ width: '25%' }}></Column>
            </DataTable>
            }

        </Panel>
    )

}