import React, {useState} from "react";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {Message} from "primereact/message";

interface NumeroFattura {
    numero: number,
    fattura: string,
    idordine: number
}

export const PrenotazioneAnnullo = (props:NumeroFattura) =>{
    return (
        <>
            <h4>Annullo Ordine</h4>
            <table style={{width:"100%"}}>
                <tr>
                    <td>
                        <p>Annullando l'ordine verra emesso rimborso dell'intero importo di {props.numero} Euro </p>
                        <p>L'operazione effettuerà anche l'eventuale annullo di Fattura o Scontrino legato
                            all'ordine</p>
                        <p>Se legato ad un ordine WooCommerce verra messo in stato annullato</p>
                    </td>
                    <td style={{textAlign:"right",verticalAlign:"top"}}>
                        <FooterDemo {...props}/>
                    </td>
                </tr>
            </table>

        </>

    );
}

export default function FooterDemo(props:NumeroFattura) {
    const [visible, setVisible] = useState(false);
    const footerContent = (
        <div>
            <Button label="No" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
            <Button label="Si" icon="pi pi-check" onClick={() => setVisible(false)} autoFocus />
        </div>
    );

    return (
        <div className="card flex justify-content-center">
            <Button label="Annulla Ordine" icon="pi pi-external-link" onClick={() => setVisible(true)} />
            <Dialog header="Conferma Annullo Ordine" visible={visible} style={{width: '50vw'}} onHide={() => {
                if (!visible) return;
                setVisible(false);
            }} footer={footerContent}>
                <p className="m-0" style={{marginBottom:"3em"}}>
                    Sei sicuro di volerr annullare l'ordine {props.idordine} creare il rimborso di {props.numero} e di
                    annullare la fattura {props.fattura}?.
                </p>
                <Message style={{marginTop:"3em"}} severity="warn" text="Attenzione verra inviata una mail di comunicazione dell'annullo al cliente all'email: {props.numero}" />

            </Dialog>
        </div>
    )
}