import {Panel} from "primereact/panel";
import {
    About,
    Collection, Cury,
    Guid,
    Links,
    Meta,
    Self,
    Title,
    Treno,
    TrenoWC,
    WpAttachment,
    WpTerm
} from "../../../models/Treni";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import {TrainService} from "../../../services/Treni";
import {DataTable} from "primereact/datatable";
import React, {useEffect} from "react";

export const ListaTreniProdotto = ()=>{
    const [listaTreni, setListaTreni] = React.useState<TrenoWC[]>([])

    const treno:TrenoWC = {
        id: 1,
        date: '2024-10-25',
        date_gmt: '2024-10-25',
        guid : {
            rendered: ''
        },
        modified: '',
        modified_gmt: '',
        slug: 'treno-ottobre-demo',
        status: '',
        type: '',
        link: '',
        title:{
            rendered: ''
        },
        template: '',
        meta: {
            _custom_wootrain_data: ''
        },
        partitions: [],
        class_list: [],
        _links: {
            self: [],
            collection: [],
            about: [],
            "wp:attachment": [],
            "wp:term": [],
            curies: [],
        }
    }

    useEffect(() => {
        let lista:TrenoWC[] = []
        lista.push(treno)
        setListaTreni(lista)

    }, []);

    return (
        <Panel header='Lista Prodotti Treno' style={{margin:"1em"}}>
            <DataTable value={listaTreni} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
                       paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                       currentPageReportTemplate="{first} to {last} of {totalRecords}">
                <Column field="id" header="#" style={{ width: '5%' }}></Column>
                <Column field="date" header="Data" style={{ width: '5%' }}></Column>
                <Column field="date" header="Treno" style={{ width: '25%' }}></Column>
                <Column field="title.rendered" header="Bigl." style={{ width: '5%' }}></Column>
                <Column field="title.rendered" header="Pacch." style={{ width: '5%' }}></Column>


                <Column field="title.rendered" header="P. Bigl." style={{ width: '5%' }}></Column>
                <Column field="title.rendered" header="P. Pacch." style={{ width: '5%' }}></Column>
                <Column field="title.rendered" header="Bambini" style={{ width: '5%' }}></Column>
                <Column field="title.rendered" header="Adulti" style={{ width: '5%' }}></Column>

                <Column body={
                    ()=>{
                        return<>
                            <Button label={"Prenotazioni"} onClick={()=>{}} />
                            <Button label={"Attesa"} onClick={()=>{}} />

                        </>
                    }
                } header="" style={{ width: '5%' }}></Column>


            </DataTable>
        </Panel>
    )

}