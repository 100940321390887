import {InputText} from "primereact/inputtext";
import {FloatLabel} from "primereact/floatlabel";
import React, {ReactNode, useEffect, useState} from "react";
import { Card } from "primereact/card";
import {Alignment, Button, Navbar} from "@blueprintjs/core";
import {useNavigate} from "react-router-dom";
import { Button as Btn } from 'primereact/button';
import config from "../../config";
import { DataTable } from "primereact/datatable";
import {Column, ColumnProps} from "primereact/column";
import {LineItem, MetaDaum2, WCOrder} from "../../models/Woocommerce";
import {Tag} from "primereact/tag";
import {InputIcon} from "primereact/inputicon";
import {ProgressBar} from "primereact/progressbar";
import { Dialog } from "primereact/dialog";
import {confirmDialog, ConfirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";

export const ManagerImportTurchino = () =>{
    const [numordine,setNumordine] = useState<number|any>(0);
    const [carica, setCarica] = useState<boolean>(false);
    const [loading,setLoading] = useState<boolean>(false);
    const [importing, setImporting] = useState<boolean>(false);
    const [scontrino, setScontrino] = useState<boolean>(false);

    const navigate = useNavigate();

    const [dataOrder,setdataOrder] = useState<WCOrder| any>([]);

    useEffect(() => {
        setCarica(false);
        setScontrino(false);
        setCarica(false);
        setLoading(false);

    },[]);

    const loadOrder = () =>{
        setdataOrder([]);
        setCarica(false);
        setLoading(true);
        fetch(`${config.TRABOCCO}/wp-json/wc/v3/orders/${numordine}?consumer_key=${config.CONSUMER_KEY}&consumer_secret=${config.CONSUMER_SECRET}`)
            .then((data)=>{return data.json()}).then((resp)=>{
            //setdataOrder(resp as WCOrder);
            let wcorder = resp as WCOrder;
            console.log(wcorder)
            setdataOrder([wcorder])
            setCarica(true)
            console.log(dataOrder[0])
            setLoading(false);
        })
    }

    const EventNameBodyTemplate = (rowData: MetaDaum2[]) => {
        let retDataElement = ''
        rowData.map((meta:MetaDaum2)=>{
            console.log(`===============>-${meta.key}-<=============`)
            console.log(meta.id)
            let key: string = meta.key;
            if(key == "Event name") {
                retDataElement += `${meta.display_value}`
            } else if (meta.key === "_wceventer_date") {
                //retDataElement = `[${meta.display_value}]`
            } else {
                //retDataElement = meta.display_value
            }
            console.log("===============>-END ${meta.key}-<=============")
        })
        return <p>{retDataElement}</p>
    };

    const EventDataBodyTemplate = (rowData:MetaDaum2[]) => {
        let retDataElement = ''
        rowData.map((meta:MetaDaum2)=>{
            console.log(`===============>-${meta.key}-<=============`)
            console.log(meta.id)
            let key: string = meta.key;
            if(key == "Event Date") {
                retDataElement += `${meta.display_value}`
            } else if (meta.key === "_wceventer_date") {
                //retDataElement = `[${meta.display_value}]`
            } else {
                //retDataElement = meta.display_value
            }
            console.log("===============>-END ${meta.key}-<=============")
        })
        return <p>{retDataElement}</p>
    };

    const ImportOrderData = ()=>{
        setImporting(true);
        fetch('http://sysb2.palleniumtravel.cloud/webhook/call_trabocchi/',{
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
            method: 'POST',
            body: JSON.stringify({'id':numordine}),
        }).then((data)=>{return data.json()}).then((resp)=>{
            console.log(resp);
            setImporting(false);
        })
    }

    const ImportOrderDataCancel = ()=>{

    }

    const EmettiScontrino = ()=>{
        setScontrino(true);
    }

    const EmettiScontrinoCancel = ()=>{

    }

    const confirmImport = () => {
        confirmDialog({
            message: `Vuoi importare l'ordine ed emettere lo scontrino?`,
            header: 'Importazione Dati Ordine',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept : ImportOrderData,
            reject: ImportOrderDataCancel
        });
    };

    const confirmScontrino = () => {
        confirmDialog({
            message: 'Vuoi emettere solo lo scontrino ed effettuare la registrazione fiscale?',
            header: 'Emissione scontrino',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            accept : EmettiScontrino,
            reject: EmettiScontrinoCancel
        });
    };

    return (
        <>

            <ConfirmDialog />
            <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading>Manager Console<sup>2</sup></Navbar.Heading>
                    <Navbar.Divider />
                    <Button className="bp5-minimal" icon="import" text="Importa Ordine Turchino" onClick={()=>{navigate(`/manager/import_turchino`)}}/>
                    <Button className="bp5-minimal" icon="import" text="Importa Ordine Ferrovie" onClick={()=>{navigate(`/manager/import_ferrovie`)}}/>
                </Navbar.Group>
            </Navbar>
            <Card title="Import ordine turchino" style={{margin:"2em"}}>
                <table>
                    <tr>
                        <td>
                            <FloatLabel>
                                <InputText id="username" value={numordine} onChange={(e) => setNumordine(e.target.value)} />
                                <label htmlFor="username">Numero Ordine</label>
                            </FloatLabel>
                        </td>
                        <td style={{paddingLeft:"5em"}}>
                            <Btn label="Carica" onClick={(event)=>loadOrder()}/>
                        </td>
                    </tr>
                </table>

            </Card>
            {loading? <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>: null}
            {carica?
                <>
                    <Card title={`Ordine Turchino ${numordine}`} style={{margin: "2em"}}>
                        <DataTable value={dataOrder} tableStyle={{minWidth: '50rem', marginBottom: "2em"}}>
                            <Column field="date_paid" header="Date Pagamento"></Column>
                            <Column field="billing.last_name" header="Cognome"></Column>
                            <Column field="billing.first_name" header="Nome"></Column>
                            <Column field="billing.email" header="Email"></Column>
                            <Column field="payment_method_title" header="Tipo Pagamento"></Column>
                            <Column field="total" header="Importo"></Column>
                            <Column field="transaction_id" header="Transaction ID"></Column>
                        </DataTable>
                        <h3>Prodotti</h3>
                        <table className={'table'}>
                            <thead className={'p-datatable-thead'}>
                            <tr>
                                <th>Codice</th>
                                <th>Prodotto</th>
                                <th>Data Evento</th>
                                <th>Prezzo</th>
                                <th>Quantita</th>
                                <th>Totale</th>
                            </tr>
                            </thead>
                            <tbody className={'p-datatable-body'}>


                            {dataOrder[0].line_items.map((line: LineItem) => {
                                console.log(line)
                                let eventName = EventNameBodyTemplate(line.meta_data)
                                return (
                                    <tr>
                                        <td>T{line.product_id}.{line.id}</td>
                                        <td><>{eventName}</></td>
                                        <td><>{EventDataBodyTemplate(line.meta_data)}</>
                                        </td>
                                        <td>{line.price}</td>
                                        <td>{line.quantity}</td>
                                        <td>{line.total}</td>
                                    </tr>
                                )

                            })}
                            </tbody>
                        </table>

                        <table style={{width: "100%",}}>

                            <tr style={{textAlign: "right"}}>
                                <td>

                                </td>
                                <td>
                                    <Btn label={"Importa"} onClick={confirmImport}/>
                                    <Btn label={"Solo Scontrino"} style={{marginLeft:"2em"}} icon="pi pi-check" iconPos="left" severity="danger" onClick={confirmScontrino}/>
                                </td>
                            </tr>
                        </table>
                    </Card>

                </>
                : null}

            <Dialog header={`Import ordine ${numordine}`} visible={importing} style={{ width: '25vw' }} onHide={() => {if (!importing) return; setImporting(false); }}>
                <p className="m-0">
                    Importazione ordine ed emissione scontrino in corso
                </p>
                <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
            </Dialog>

            <Dialog header={`Emissione Scontrino per ordine ${numordine}`} visible={scontrino} style={{ width: '25vw' }} onHide={() => {if (!scontrino) return; setScontrino(false); }}>
                <p className="m-0">
                    Emissione scontrino in corso
                </p>
                <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
            </Dialog>
        </>

    )
}

