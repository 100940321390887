import {Panel} from "primereact/panel";
import React from "react";
import {Button} from "primereact/button";
import {InputNumber} from "primereact/inputnumber";
import {TrainService} from "../../../services/Treni";
import {useNavigate} from "react-router-dom";


export const CaricaTreno = ()=>{

    const [trenoid, setTrenoid] = React.useState<number|null>();
    const [isLoading, setIsLoading] = React.useState(false);

    const navigate = useNavigate();

    const loadTreno = ()=>{
        setIsLoading(true)
        alert("CaricaTreni " + trenoid);
        const trenoService = new TrainService();
        // @ts-ignore
        trenoService.importTreno(trenoid)
            .then(treni => {
                console.log(treni)
                if(treni.status == 404){
                    console.log("Treno non caricato");
                }
                navigate('/prenotazioni/treni')
            })
            .catch(error => {
                console.log('Error:', error)
                alert('Error:'+ error);
            });
    }

    return (
        <Panel style={{margin: "1em"}} header={"Carica Treno"}>
            <table>
                <tbody>
                <tr>
                    <td>
                        <InputNumber onChange={(e) => setTrenoid(e.value)} value={trenoid}/>
                    </td>
                    <td><Button label={"Importa Treno"} onClick={() => {
                        loadTreno()
                    }}/></td>
                </tr>

                </tbody>
            </table>
        </Panel>
    )

}