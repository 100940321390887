import React, {useState} from "react";
import {Inplace, InplaceContent, InplaceDisplay} from "primereact/inplace";
import {Splitter, SplitterPanel} from "primereact/splitter";
import {Editor} from "primereact/editor";

export const PrenotazioneTicket = () =>{
    const [text, setText] = useState('');

    // @ts-ignore
    return (
        <>

        <h4>Ticket Supporto Prenotazione</h4>
            <Splitter style={{ height: '100%' }}>
                <SplitterPanel className="flex align-items-center justify-content-center">
                    <Editor value={text} title={"Ticket"} aria-rowcount={5} onTextChange={(e) => {
                        setText(e.textValue);
                    }} style={{ height: '320px' }} />
                </SplitterPanel>

                <SplitterPanel className="flex align-items-center justify-content-center" style={{padding:"2em"}}>

                    <Inplace closable={true} style={{marginTop:"1em"}}>
                        <InplaceDisplay>Chiedere conferma ad Hotel</InplaceDisplay>
                        <InplaceContent>
                            <p className="m-0">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </InplaceContent>
                    </Inplace>
                    <hr />
                    <Inplace closable={true} style={{marginTop:"2em"}}>
                        <InplaceDisplay>Hotel 3 stelle non disponibile</InplaceDisplay>
                        <InplaceContent>
                            <p className="m-0">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                                Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            </p>
                        </InplaceContent>
                    </Inplace>
                </SplitterPanel>
            </Splitter>

        </>
    );

}