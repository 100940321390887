import React, {useEffect, useState} from "react";
import {Panel} from "primereact/panel";
import {Chart} from "primereact/chart";
import {GridSearchMonth} from "../../components/GridSearchMonth";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {fetchWithBearer} from "../../services/ApiCall";
import {Button} from "primereact/button";
import {Alignment, Navbar, Button as ButtonNav} from "@blueprintjs/core";
import {useNavigate} from "react-router-dom";
import {ProgressBar} from "primereact/progressbar";

/*

    {
        "mese":"Dicembre",
        "scontrini" : 0,
        "importo" : 0.0
    }

 */

const datiChartMensile: any[] = []

interface sumuptransaction {
    transaction:string,
    description : string,
    data: string,
    value: number
}

export interface Transaction {
    amount: number
    client_transaction_id: string
    currency: string
    entry_mode: string
    id: string
    installments_count: number
    payment_type: string
    payout_plan: string
    payouts_received: number
    payouts_total: number
    product_summary: string
    refunded_amount: number
    status: string
    timestamp: string
    transaction_code: string
    transaction_id: string
    type: string
    user: string
}

export const TransazioniSumup = ()=>{
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [showTable, setShowTable] = useState<boolean>(false)
    const [meseanalisi, setMeseanalisi] = useState<number>(0);

    const [isAllign,setIsAllign] = useState<boolean>(false)

    const [data,setData] = useState<any>(null)
    const [error, setError] = useState<string | null>(null);

    const [transactionList,setTransactionList] = useState<Transaction[]>([])

    const navigate = useNavigate();

    useEffect(() => {
        let mesi:string[] = []
        let valori:number[] = []
        let scontrini:number[] = []
        const documentStyle = getComputedStyle(document.documentElement);
        const textColor = documentStyle.getPropertyValue('--text-color');
        const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
        const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
        datiChartMensile.forEach((mensile) => {
            mesi.push(mensile.mese)
            valori.push(mensile.importo)
            scontrini.push(mensile.scontrini)
        })
        const data = {
            labels: mesi,
            datasets: [
                {
                    label: 'Scontrini',
                    backgroundColor: documentStyle.getPropertyValue('--blue-500'),
                    borderColor: documentStyle.getPropertyValue('--blue-500'),
                    data: scontrini
                },
                {
                    label: 'Importo mensile',
                    backgroundColor: documentStyle.getPropertyValue('--pink-500'),
                    borderColor: documentStyle.getPropertyValue('--pink-500'),
                    data: valori
                }
            ]
        }

        const options = {
            indexAxis: 'y',
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        fontColor: textColor
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: textColorSecondary,
                        font: {
                            weight: 500
                        }
                    },
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                },
                y: {
                    ticks: {
                        color: textColorSecondary
                    },
                    grid: {
                        color: surfaceBorder,
                        drawBorder: false
                    }
                }
            }
        };
        setChartData(data)
        setChartOptions(options);
        fetchData();
    }, []);


    const fetchData = async () => {

        const today = new Date();

        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Aggiunge 1 perché i mesi vanno da 0 a 11
        const day = String(today.getDate()).padStart(2, '0');

        const token = 'sup_sk_jvmx7ViqBSwV20J7tHFqBjbLhDs1Mf0Vx'; // Sostituisci con il tuo token
        const url = `https://api.sumup.com/v0.1/me/transactions/history?order=descending&limit=100&changes_since=${year}-${month}-${day}T00:00:00.229Z`; // Sostituisci con l'URL della tua API

        try {
            let payload = {
                "order" : "descending",
                "limit" : 10
            }
            console.log("avvio fetching dati")
            fetchWithBearer(url, token,null).then((data)=>{return data.json()}).then((result)=>{
                setData(result);
                console.log("fine fetching dati")
                console.log(result)
                setTransactionList(result.items as Transaction[])
            });

        } catch (err) {
            // @ts-ignore
            setError(err.message);
        }
    }

    const updateDataTable = (e:any) => {
        setShowTable(true)
        fetchData()
        alert("Mese Selezionato: " + e)
    }

    const timeDataConverter = (data:any)=>{
        console.log(data)
        let data_converted:string = data.timestamp.split("T")[0]
        return (<>{data_converted}</>)
    }

    const scontrina = (id_tx:string) => {
        alert("Scontrinato " + id_tx)
    }

    const actionButton = (data:any) => {

        return (
            <Button label={"Scontrina"} onClick={()=>{scontrina(data.transaction_id)}} />
        )
    }


    const importaTransazioni = async ()=>{
        setIsAllign(true);
        await fetch("http://sysb2.palleniumtravel.cloud/webhook/call_sumup_align")
            .then((result)=>{return result.json()})
            .then((data)=>{console.log(data);setIsAllign(false);})

    }

    return (
        <>
            <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading>Fatture</Navbar.Heading>
                    <Navbar.Divider />
                    <ButtonNav className="bp5-minimal" icon="document" text="Ordini"  onClick={()=>{navigate('/fatture/ordini')}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Fatture Emesse"  onClick={()=>{navigate('/fatture/emesse')}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Corrispettivi"  onClick={()=>{navigate('/fatture/corrispettivi')}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Rimborsi"  onClick={()=>{navigate('/fatture/rimborsi')}}/>

                    <ButtonNav className="bp5-minimal" icon="document" text="Transazioni Sumup" onClick={()=>{navigate('/fatture/sumup')}} />
                    <ButtonNav className="bp5-minimal" icon="document" text="Transazioni Stripe"  onClick={()=>{navigate('/fatture/stripe')}}/>

                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    <ButtonNav className="bp5-minimal" icon="download" text="Importa Transazioni" onClick={()=>{importaTransazioni()}} />
                    <ButtonNav className="bp5-minimal" icon="tag" text="Transazioni"  onClick={()=>{navigate('/fatture/sumup')}}/>
                </Navbar.Group>
            </Navbar>
            {isAllign?<ProgressBar mode="indeterminate" style={{ height: '10px' }}></ProgressBar>:null}
            <Panel header="Transazioni SumUP" style={{margin:"1em"}} >
                <Chart type="bar" data={chartData} options={chartOptions} />
            </Panel>
            <Panel header="Gestione Transazioni Giornaliere" style={{margin:"1em"}}>
                <GridSearchMonth label={"Selezione mese"} onDraw={()=>{}} onSelected={(e:any)=>{updateDataTable(e)}} />
                {showTable?
                <DataTable value={transactionList} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="id" header="ID TX"></Column>
                    <Column field="timestamp" header="Data" body={timeDataConverter}></Column>
                    <Column field="transaction_code" header="Code" ></Column>
                    <Column field="payment_type" header="Tipo"></Column>
                    <Column field="product_summary" header="Prodotto"></Column>
                    <Column field="amount" header="Importo" style={{textAlign:"right"}}></Column>
                    <Column header="Importo" body={actionButton}></Column>
                </DataTable>
                    :null}
            </Panel>
        </>
    )
}
