import React from "react";
import {Alignment, Button, Navbar} from "@blueprintjs/core";

export default  class FinanziariaApp extends React.PureComponent {
    public render() {

        return(
            <>
                <Navbar>
                    <Navbar.Group align={Alignment.LEFT}>
                        <Navbar.Heading>Finanza Aziendale</Navbar.Heading>
                        <Navbar.Divider />
                        <Button className="bp5-minimal" icon="document" text="Andamento Vendite" />
                        <Button className="bp5-minimal" icon="document" text="Cash Flow" />
                        <Button className="bp5-minimal" icon="document" text="Conto Economico" />
                        <Button className="bp5-minimal" icon="document" text="Stato Patrimoniale" />
                        <Button className="bp5-minimal" icon="document" text="Riclassificazione" />
                        <Button className="bp5-minimal" icon="document" text="Indicatori Finanziari" />


                    </Navbar.Group>
                </Navbar>
            </>
        )

    }
}