import {Panel} from "primereact/panel";
import React, {useEffect, useState} from "react";
import {ProgressBar} from "primereact/progressbar";
import {ListTrainServiceWC, TrainService} from "../../../services/Treni";
import {ListaTreniWC} from "../../../models/Treni";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Button} from "primereact/button";

export const ListaTreni = ()=>{
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [listaTreni, setListaTreni] = useState<ListaTreniWC>([])
    useEffect(()=>{
        setIsLoading(true);
        const trainService = new ListTrainServiceWC();
        trainService.getTreni()
            .then(treni => {

                setListaTreni(treni)
                setIsLoading(false)
                console.log('Treni:', treni);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    },[])

    return (
        <Panel style={{margin:"1em"}} header={"Lista Tipo Treni trasferibili"}>

            {isLoading?
                <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                :
                <DataTable value={listaTreni} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
                           paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                           currentPageReportTemplate="{first} to {last} of {totalRecords}">
                    <Column field="id" header="#" style={{ width: '25%' }}></Column>
                    <Column field="date" header="date" style={{ width: '25%' }}></Column>
                    <Column field="title.rendered" header="title" style={{ width: '25%' }}></Column>
                    <Column body={(treno)=> {
                        return (<Button label={"import"} onClick={() => {
                            const trenoService = new TrainService();
                            trenoService.importTreno(treno.id)
                                .then(treni => {
                                    console.log(treni)
                                    alert("Import successfully imported");
                                })
                                .catch(error => {
                                    console.log('Error:', error)
                                    alert('Error:'+ error);
                                });

                        }}/>)
                    } } header="partitions" style={{ width: '25%' }}></Column>
                </DataTable>
            }

        </Panel>
    )

}