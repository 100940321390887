import React, {useRef, useState} from "react";
import {Table} from "react-bootstrap";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";

interface FatturaEmissione {
    tipo: string,
    data_creazione: string,
    data_validazione: string,
    data_invio: string,
    response: string,
    isCreated: boolean,
    numero:string
}

export const PrenotazioneEmissioneSdi = () =>{
    const toastVerifica = useRef(null);
    const toastEmetti = useRef(null);
    const toastFattura = useRef(null);

    const [verificata, setVerificata] = useState(false);
    const [emissione, setEmissione] = useState(false);
    const [creata,setCreata] = useState(false);

    const [fattura,setFattura] = useState({tipo:'',data_creazione:'',data_validazione:'',data_invio:'',response:'',isCreated:false} as FatturaEmissione);

    const creaFattura = ()=>{
        // @ts-ignore
        toastFattura?.current.show({ severity: 'success', summary: 'Creazione fattura/Scontrino', detail: 'Creazione fattura/scontrino effettuata' });
        setCreata(true)
        setFattura({tipo:'Fattura',data_creazione:'28/06/2024',data_validazione:'',data_invio:'',response:'',isCreated:true,numero:'1/F'});
    }

    const showEmetti = () =>{
        // @ts-ignore
        toastEmetti?.current.show({ severity: 'success', summary: 'Info SDI', detail: 'Invio Effettuato' });
        setFattura({tipo:'Fattura',data_creazione:'28/06/2024',data_validazione:'28/06/2024',data_invio:'28/06/2024',response:'',isCreated:true,numero:'1/F'})
        setEmissione(true)
        setVerificata(true)
    }

    const showVerifica = () =>{
        // @ts-ignore
        toastVerifica?.current.show({ severity: 'success', summary: 'Info SDI', detail: 'Verifica Effettuata' });
        setVerificata(true)
        setFattura({tipo:'Fattura',data_creazione:'28/06/2024',data_validazione:'28/06/2024',data_invio:'',response:'',isCreated:true,numero:'1/F'})
    }


    return (<>
            <Toast ref={toastVerifica} />
            <Toast ref={toastEmetti} />
            <Toast ref={toastFattura} />
            <table style={{width: "100%"}}>
                <tr>
                    <td>
                        <h4>Emissione Fattura/Scontrino SDI</h4>
                        <p>L'emissione dello scontrino o fattura è possibile solo se non gia emessi</p>
                    </td>
                    <td style={{textAlign:"right"}}>
                        {!creata?<Button label="Crea Fattura" onClick={creaFattura} severity="info"/>:null}
                        {creata?<>
                            {!verificata?<Button style={{marginLeft:"1em"}} label="Verifica" onClick={showVerifica} severity="help"/>:null}
                            {!emissione?<Button style={{marginLeft:"1em"}} onClick={showEmetti} label="Emetti" severity="warning"/>:null}
                            </>
                        :null}
                    </td>
                </tr>
            </table>

            <Table className="table table-striped table-bordered">
                <thead>
                <tr>
                    <th>Numero</th>
                    <th>Tipo</th>
                    <th>Data Creazione</th>
                    <th>Data Validazione</th>
                    <th>Data Invio</th>
                    <th>Response</th>
                </tr>
                </thead>
                <tbody>
                {!fattura.isCreated?
                    <tr>
                        <td colSpan={5} style={{textAlign:"center"}}>Nessuna emisione effettuata</td>
                    </tr>
                    :
                    <tr>
                        <td>{fattura.numero}</td>
                        <td>{fattura.tipo}</td>
                        <td>{fattura.data_creazione}</td>
                        <td>{fattura.data_validazione}</td>
                        <td>{fattura.data_invio}</td>
                        <td>OK</td>
                    </tr>
                }
                </tbody>
                <th></th>
            </Table>
        </>
    );
}