
import React from 'react';
import { Menubar } from 'primereact/menubar';
import { MenuItem } from 'primereact/menuitem';
import {Alignment, Button, Navbar} from "@blueprintjs/core";
import {useNavigate} from "react-router-dom";

export const Manager = () => {
    const navigate = useNavigate();

    return (

        <>
            <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading>Manager Console<sup>2</sup></Navbar.Heading>
                    <Navbar.Divider />
                    <Button className="bp5-minimal" icon="import" text="Importa Ordine Turchino" onClick={()=>{navigate(`/manager/import_turchino`)}}/>
                    <Button className="bp5-minimal" icon="import" text="Importa Ordine Ferrovie" onClick={()=>{navigate(`/manager/import_ferrovie`)}}/>
                </Navbar.Group>
            </Navbar>
        </>
    )
}
        