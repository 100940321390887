import React from "react";
import {MenuProdotti} from "../components/MenuProdotti";
import {Panel} from "primereact/panel";

export default  class ProdottiApp extends React.PureComponent {
    public render() {

        return(
            <>
                <MenuProdotti />

                <Panel header={"Gestione Prodotti"} style={{ margin:"1em" }}>

                </Panel>
            </>
        )

    }
}