// src/config.ts

const ENV = process.env.NODE_ENV;

const dev = {
    API_URL: "http://localhost:8000/api",
    API_AUTH_URL: "http://localhost:8000/api/token",
    API_REFRESH_URL: "http://localhost:8000/api/token/refresh",
    CONSUMER_KEY : "ck_4e412919960d1297db91c67b9fa86bdcb7f0967b",
    CONSUMER_SECRET : "cs_3e6fc8311395fb6146cd9d31ab18717952d2805b",
    TRABOCCO : "https://www.traboccoturchino.com/",
    FERROVIE : ""
};

const prod = {
    API_URL: "http://sysb2.palleniumtravel.cloud/api",
    API_AUTH_URL : "http://sysb2.palleniumtravel.cloud/token",
    API_REFRESH_URL: "http://sysb2.palleniumtravel.cloud/token/refresh",
    CONSUMER_KEY : "ck_4e412919960d1297db91c67b9fa86bdcb7f0967b",
    CONSUMER_SECRET : "cs_3e6fc8311395fb6146cd9d31ab18717952d2805b",
    TRABOCCO : "https://www.traboccoturchino.com/",
    FERROVIE_WP : "https://fdp.netsons.org/wp-json/wp/v2/",
    FERROVIE_WC : "https://fdp.netsons.org/wp-json/wc/v3/",
    REFRESH_URL : "http://sysb2.palleniumtravel.cloud/api/token/refresh"
};

//const config = ENV === "development" ? dev : prod;
const config = prod;
export default config;
