
import {Panel} from "primereact/panel";
import {useNavigate} from "react-router-dom";
import {Alignment, Button, Navbar} from "@blueprintjs/core";
import React, {useState} from "react";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {Calendar} from "primereact/calendar";
import {Checkbox} from "primereact/checkbox";

import {Button as Btn} from "primereact/button";

export const RimborsiFattura = ()=>{

    const navigate = useNavigate();
    const [riemissioneDoc,setRimissioneDoc] = useState<boolean>(false);
    const [messaggio,setMessaggio] = useState<string>("");
    const [formData, setFormData] = useState({
        tipo_rimborso: 0,
        num_documento: '',
        importo_documento: 0,
        importo_rimborso: 0,
        data_rimborso: null,
        dati_documento: {},
        dati_rimborso: {},
        contabilizzato: false,
        riemissione_documento: false,
        id_doc_riemesso: 0,
        tipo_doc_riemesso: 0,
        dati_doc_riemesso: {}
    });

    const handleChange = (e:any) => {
        const { name, value, type, checked } = e.target;
        if(formData.importo_documento > formData.importo_rimborso){
            setRimissioneDoc(true)
            formData.riemissione_documento = true
            formData.tipo_doc_riemesso = 3;
        } else {
            setRimissioneDoc(false)
            formData.riemissione_documento = false
            formData.tipo_doc_riemesso = 0;
        }
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e:any) => {
        e.preventDefault();
        // chiamata a Service Rimborso
        setMessaggio("Rimborso effettuato \n Importo Rimborso: " + formData.importo_rimborso + " €\n Documento Emesso: " + formData.tipo_doc_riemesso);

    };

    const getCookie = (name:any) => {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    };

    return (
        <>
            <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading>Fatture</Navbar.Heading>
                    <Navbar.Divider />
                    <Button className="bp5-minimal" icon="document" text="Ordini"  onClick={()=>{navigate('/fatture/ordini')}}/>
                    <Button className="bp5-minimal" icon="document" text="Fatture Emesse"  onClick={()=>{navigate('/fatture/emesse')}}/>
                    <Button className="bp5-minimal" icon="document" text="Corrispettivi"  onClick={()=>{navigate('/fatture/corrispettivi')}}/>
                    <Button className="bp5-minimal" icon="document" text="Rimborsi"  onClick={()=>{navigate('/fatture/rimborsi')}}/>

                    <Button className="bp5-minimal" icon="document" text="Transazioni Sumup" onClick={()=>{navigate('/fatture/sumup')}} />
                    <Button className="bp5-minimal" icon="document" text="Transazioni Stripe"  onClick={()=>{navigate('/fatture/stripe')}}/>

                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    <Navbar.Divider />
                    <Navbar.Heading>Nuovo Rimborso</Navbar.Heading>
                    <Button className="bp5-minimal" icon="label" text="Scontrini" onClick={()=>{navigate('/fatture/rimborso/scontrino')}} />
                    <Button className="bp5-minimal" icon="document" text="Fatture"  onClick={()=>{navigate('/fatture/rimborso/fattura')}}/>
                    <Navbar.Divider />
                    <Button className="bp5-minimal" icon="book" text="Contabile"  onClick={()=>{navigate('/fatture/rimborso/contabile')}}/>
                </Navbar.Group>
            </Navbar>

            <Panel header={"Rimborso Fattura"} style={{margin: "1em"}}>
                <form  onSubmit={handleSubmit}>

                    <table>

                        <tbody>
                        <tr>
                            <td>
                                <span style={{float:"left",marginLeft:"1em"}}>Numero Documento</span>
                                <InputText
                                name="num_documento"
                                value={formData.num_documento}
                                onChange={handleChange}
                                placeholder="Numero Documento"
                                style={{float:"left",marginLeft:"1em",marginBottom:"2em"}}
                            /></td>
                            <td>
                                <span style={{float: "left", marginLeft: "1em"}}>Importo Documento</span>
                                <InputNumber
                                    name="importo_documento"
                                    value={formData.importo_documento}
                                    onValueChange={handleChange}
                                    mode="decimal"
                                    minFractionDigits={2}
                                    style={{float: "left", marginLeft: "1em",marginBottom:"2em"}}
                                /></td>
                            <td>
                                <span style={{float: "left", marginLeft: "1em"}}>Rimborso</span>
                                <InputNumber
                                    name="importo_rimborso"
                                    value={formData.importo_rimborso}
                                    onValueChange={handleChange}
                                    mode="decimal"
                                    minFractionDigits={2}
                                    style={{float: "left", marginLeft: "1em",marginBottom:"2em"}}
                                /></td>
                            <td></td>
                            <td></td>

                        </tr>
                        <tr>
                            <td><Checkbox
                                name="contabilizzato"
                                checked={formData.contabilizzato}
                                onChange={handleChange}
                                style={{float: "left", marginLeft: "1em",marginRight:"1em"}}
                            /> Contabilizzato
                            </td>
                            <td><Checkbox
                                name="riemissione_documento"
                                checked={formData.riemissione_documento}
                                onChange={handleChange}
                                style={{float: "left", marginLeft: "1em",marginRight:"1em"}}
                            /> Riemissione Documento
                            </td>
                            <td>
                                <span style={{float: "left", marginLeft: "1em"}}>Documento da emettere</span>
                                <Dropdown
                                    editable={false}
                                    name="tipo_doc_riemesso"
                                    value={formData.tipo_doc_riemesso}
                                    options={[
                                        {label: 'NESSUNO', value: 0},
                                        {label: 'NOTA CREDITO', value: 3}
                                    ]}
                                    onChange={handleChange}
                                    placeholder="Tipo Doc Riemesso"
                                    style={{float: "left", marginLeft: "1em"}}
                                /></td>

                            <td></td>

                            <td></td>
                        </tr>
                        </tbody>

                    </table>

                    <Btn type="submit" label="Emissione Rimborso" style={{marginTop:"2em"}}/>
                </form>
                <pre style={{float:"right", display:"block",width:"30em",textAlign:"left"}}>{messaggio}</pre>
            </Panel>
        </>
    )

}