import React, {useEffect, useState} from "react";
import {Panel} from "primereact/panel";
import {MenuProdotti} from "../../components/MenuProdotti";
import {ProgressBar} from "primereact/progressbar";

export const ProdottiTask = ()=> {

    const [isLoading,setIsLoading] = useState<boolean>(true)


    useEffect(() => {

        setIsLoading(true);

    }, []);

        return(
            <>
                <MenuProdotti />

                {isLoading?
                    <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                    :

                    <Panel header={"Task Conferma Prodotti"} style={{ margin:"1em" }}>

                    </Panel>
                }
            </>
        )


}