// src/services/authService.ts


import axios from "axios";
import config from "../config";

interface LoginData {
    username: string;
    password: string;
}

export const login = async (data: LoginData) => {
    console.log(data)
    try {
        // Simulate an API call
        /*
        let response = await fetch('http://127.0.0.1:8000/api/token',  {
            method: 'POST',
            headers: {
                'Content-Type': 'text/html',
            },
                body:JSON.stringify(data),
            mode:"no-cors"
        }).then(response => {return response}).then(datalogin => datalogin.json());
        console.log(response)
        if (!response.ok) {
            // @ts-ignore
            throw new Error(response.text() || 'Invalid email or password');
        }
        return data;
        */
        try{
            const response = await axios.post(`${config.API_URL}/token`, data);
            console.log(response.data)
            sessionStorage.setItem("token", response.data.access);
            sessionStorage.setItem("refresh", response.data.refresh);
            return response.data;
        } catch (error){
            throw new Error("Error logging in");
        }

    } catch (error) {
        // @ts-ignore
        throw new Error(error.message || 'Invalid email or password');
    }
};
