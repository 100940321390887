import {Button} from "primereact/button";
import {useState} from "react";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";

interface GridProps {
    label:string,
    onDraw:any,
    onSelected:any,
}

export const GridSearchMonth = (props:GridProps) => {

    const [selectedMonth, setSelectedMonth] = useState<string|null>(null);
    const months = [
        { name: 'Gennaio', code: '1' },
        { name: 'Febbraio', code: '2' },
        { name: 'Marzo', code: '3' },
        { name: 'Aprile', code: '4' },
        { name: 'Maggio', code: '5' },
        { name: 'Giugno', code: '6' },
        { name: 'Luglio', code: '7' },
        { name: 'Agosto', code: '8' },
        { name: 'Settembre', code: '9' },
        { name: 'Ottobre', code: '10' },
        { name: 'Novembre', code: '11' },
        { name: 'Dicembre', code: '12' }
    ];
    const selectMonth = (e:DropdownChangeEvent)=>{
        setSelectedMonth(e.value);
    }
    return (
        <>
        <div className="flex flex-wrap" style={{maxWidth:"500px"}}>
            <div className="flex align-items-center justify-content-center font-bold m-2 border-round" style={{}}>{props.label}
            </div>
            <div className="flex align-items-center justify-content-center  font-bold m-2 border-round"
                 style={{}}>
                <Dropdown value={selectedMonth} onChange={(e) => {
                    selectMonth(e);
                }} options={months} optionLabel="name"
                          placeholder="Seleziona Mese" className="w-full md:w-14rem" />
            </div>
            <div className="flex align-items-center justify-content-center  font-bold m-2 border-round"
                 style={{}}>
                <Button label={"Cerca"} onClick={()=>{ // @ts-ignore
                    props.onSelected(selectedMonth.code)}} />
            </div>
        </div>
        </>
    )
}