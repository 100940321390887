import {
    Alignment, AnchorButton,
    Button,
    Classes,
    H5,
    Navbar,
    NavbarDivider,
    NavbarGroup,
    NavbarHeading,
    Switch,
} from "@blueprintjs/core";
import { Breadcrumbs, Breadcrumb, BreadcrumbProps, Icon } from "@blueprintjs/core";

import { Card, Menu, MenuDivider, MenuItem, Popover } from "@blueprintjs/core";

import React from "react";

import {Link, NavLinkProps, useNavigate} from "react-router-dom";

export default  class CrmApp extends React.PureComponent {
    public render() {

        return(
            <>
                <Navbar>
                    <Navbar.Group align={Alignment.LEFT}>
                        <Navbar.Heading>CRM</Navbar.Heading>
                        <Navbar.Divider />
                        <Button className="bp5-minimal" icon="document" text="Clienti" />
                        <Button className="bp5-minimal" icon="document" text="Task" />
                        <Button className="bp5-minimal" icon="document" text="Assistenza" />
                        <Button className="bp5-minimal" icon="document" text="Liste Attesa" />

                    </Navbar.Group>
                </Navbar>

            </>
        )

    }
}