import React from "react";
import {Alignment, Button, Navbar} from "@blueprintjs/core";
import {useNavigate} from "react-router-dom";
import {Panel} from "primereact/panel";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

interface Transazione {
    id:number
    tipo:string
    data_transazione:string
    data_contabiler:string
    importo:number
    descrizione: string
}

export const FattureApp = ()=> {

        const navigate = useNavigate();
        const [transactionList, setTransactionList] = React.useState<Transazione[]>([]);

        return(
            <>
                <Navbar>
                    <Navbar.Group align={Alignment.LEFT}>
                        <Navbar.Heading>Fatture</Navbar.Heading>
                        <Navbar.Divider />
                        <Button className="bp5-minimal" icon="document" text="Ordini"  onClick={()=>{navigate('/fatture/ordini')}}/>
                        <Button className="bp5-minimal" icon="document" text="Fatture Emesse"  onClick={()=>{navigate('/fatture/emesse')}}/>
                        <Button className="bp5-minimal" icon="document" text="Corrispettivi"  onClick={()=>{navigate('/fatture/corrispettivi')}}/>
                        <Button className="bp5-minimal" icon="document" text="Rimborsi"  onClick={()=>{navigate('/fatture/rimborsi')}}/>

                        <Button className="bp5-minimal" icon="document" text="Transazioni Sumup" onClick={()=>{navigate('/fatture/sumup')}} />
                        <Button className="bp5-minimal" icon="document" text="Transazioni Stripe"  onClick={()=>{navigate('/fatture/stripe')}}/>

                    </Navbar.Group>
                </Navbar>


            <Panel header={"Contabilità Fatture/Corrispettivi"} style={{margin:"1em"}}>
                <DataTable value={transactionList} tableStyle={{ minWidth: '50rem' }}>
                    <Column field="id" header="#"></Column>
                    <Column field="tipo" header="Tipo" ></Column>
                    <Column field="descrizione" header="Descrizione" ></Column>
                    <Column field="data_transazione" header="Data Trans."></Column>
                    <Column field="data_contabile" header="Data Contabile"></Column>
                    <Column field="importo" header="Importo" style={{textAlign:"right"}}></Column>

                </DataTable>
            </Panel>
            </>
        )
}