import React from "react";
import {Alignment, Button, Navbar} from "@blueprintjs/core";
import {useNavigate} from "react-router-dom";

export const PrenotazioniApp = ()=> {

    const navigate = useNavigate();

        return(
            <>
                <Navbar>
                    <Navbar.Group align={Alignment.LEFT}>
                        <Navbar.Heading>Prenotazioni</Navbar.Heading>
                        <Navbar.Divider />
                        <Button className="bp5-minimal" icon="document" text="Ordini" onClick={()=>{navigate(`ordini`)}}/>
                        <Button className="bp5-minimal" icon="document" text="Prodotti" onClick={()=>{navigate(`prodotti`)}}/>
                        <Button className="bp5-minimal" icon="document" text="Treni" onClick={()=>{navigate(`treni`)}}/>
                        <Button className="bp5-minimal" icon="document" text="Servizi" onClick={()=>{navigate(`servizi`)}}/>
                        <Button className="bp5-minimal" icon="document" text="Pacchetti" onClick={()=>{navigate(`pacchetti`)}}/>
                        <Button className="bp5-minimal" icon="document" text="Biglietti" onClick={()=>{navigate(`biglietti`)}}/>
                    </Navbar.Group>
                </Navbar>
            </>
        )


}