import React, {useState} from "react"
import {useNavigate} from "react-router-dom";
import {Alignment, Navbar} from "@blueprintjs/core";
import {Button as ButtonNav} from "@blueprintjs/core/lib/esm/components/button/buttons";
import {Panel} from "primereact/panel";
import {CaricaTreno} from "./treni/CaricaTreni";

import {GestioneTreni} from "./treni/GestioneTreni";
import {ListaTreni} from "./treni/ListaTreni";
import {ListaTreniProdotto} from "./treni/ListaTreniProdotto";


export const TreniPanel = ()=>{
    const navigate = useNavigate();
    const [itemInterno,setItemInterno] = useState<number>(99)
    return (
        <>
            <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading>Prodotti</Navbar.Heading>
                    <Navbar.Divider />
                    <ButtonNav className="bp5-minimal" icon="document" text="Ordini" onClick={()=>{navigate(`/prenotazioni/ordini`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Prodotti" onClick={()=>{navigate(`/prenotazioni/prodotti`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Treni" onClick={()=>{navigate(`/prenotazioni/treni`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Servizi" onClick={()=>{navigate(`/prenotazioni/servizi`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Pacchetti" onClick={()=>{navigate(`/prenotazioni/pacchetti`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Biglietti" onClick={()=>{navigate(`/prenotazioni/biglietti`)}}/>
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    <ButtonNav className="bp5-minimal" icon="barcode" text="Lista Prodotti Treno" onClick={()=>{setItemInterno(99)}}/>
                    <ButtonNav className="bp5-minimal" icon="comparison" text="Lista Tipi Treni" onClick={()=>{setItemInterno(0)}}/>
                    <ButtonNav className="bp5-minimal" icon="many-to-one" text="Carica Lista Treni" onClick={()=>{setItemInterno(1)}}/>
                    <ButtonNav className="bp5-minimal" icon="import" text="Importa Treno" onClick={()=>{setItemInterno(2)}}/>
                </Navbar.Group>
            </Navbar>
            {itemInterno === 99? <ListaTreniProdotto /> : null}
            {itemInterno === 0? <GestioneTreni /> : null}
            {itemInterno === 1? <ListaTreni /> : null}
            {itemInterno === 2? <CaricaTreno /> : null}


        </>
    )
}