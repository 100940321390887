import {Alignment, Menu, Navbar} from "@blueprintjs/core"
import {Button as ButtonNav} from "@blueprintjs/core/lib/esm/components/button/buttons";
import React from "react";
import {useNavigate} from "react-router-dom";



export const MenuProdotti= () => {
    const navigate = useNavigate();


    return (
        <>


    <Navbar>
        <Navbar.Group align={Alignment.LEFT}>
            <Navbar.Heading>Prodotti</Navbar.Heading>
            <Navbar.Divider />
            <ButtonNav className="bp5-minimal" icon="document" text="Prodotti" onClick={()=>{navigate(`/prodotti`)}}/>
            <ButtonNav className="bp5-minimal" icon="document" text="Prodotti Treno" onClick={()=>{navigate(`/prodotti/treno`)}}/>
            <ButtonNav className="bp5-minimal" icon="document" text="Turchino" onClick={()=>{navigate(`/prodotti/turchino`)}}/>
            <ButtonNav className="bp5-minimal" icon="document" text="Servizi" onClick={()=>{navigate(`/prodotti/servizi`)}}/>
            <ButtonNav className="bp5-minimal" icon="document" text="Property Manager" onClick={()=>{navigate(`/prodotti/propman`)}}/>
            <ButtonNav className="bp5-minimal" icon="document" text="Processo Conferma" onClick={()=>{navigate(`/prodotti/tasks`)}}/>
        </Navbar.Group>
    </Navbar>

        </>
    )
}