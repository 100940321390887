import React from "react";

export default  class HomeApp extends React.PureComponent {
    public render() {

        return(
            <>
                <h1>Home</h1>
            </>
        )

    }
}