export const fetchWithBearer = async (url: string, token: string,body:any|null,options: RequestInit = {}) => {
    console.log("inizio dati " + url + " con token " + token + " con dati " + JSON.stringify(body));
    const response = await fetch(url, {
        ...options,
        headers: {
            ...options.headers,
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        method: "GET",

    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    console.log(response.status);
    console.log("fine dati");
    return response;
};