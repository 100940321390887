import React, {useEffect, useState} from "react"
import {useNavigate} from "react-router-dom";
import {Alignment, Navbar} from "@blueprintjs/core";
import {Button as ButtonNav} from "@blueprintjs/core/lib/esm/components/button/buttons";
import {Panel} from "primereact/panel";
import {ProgressBar} from "primereact/progressbar";
import {Ordine} from "./ordini";
import {OrdineService} from "../../services/OrderService";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";


export const PacchettiPanel = ()=>{
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [ordini,setOrdini] = useState<Ordine[]>([]);

    useEffect(()=>{
        setOrdini([])
        setIsLoading(true);
        const ordineService = new OrdineService();
        ordineService.listaPacchetti()
            .then(ordini => {
                setOrdini(ordini)
                setIsLoading(false)
                console.log('Ordini:', ordini);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    },[])

    const scontrinatoBody = (ordine: Ordine)=>{
        let label:any = ''
        if(ordine.fatturato){
            let data_sdi = JSON.parse(ordine.sdi_data);
            label = <> {data_sdi?.data?.number}/{data_sdi?.data?.numeration}</>
        } else {
            label = <b>No</b>
        }
        return(<>{label}</>)
    }

    return (
        <>
            <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading>Prodotti</Navbar.Heading>
                    <Navbar.Divider />
                    <ButtonNav className="bp5-minimal" icon="document" text="Ordini" onClick={()=>{navigate(`/prenotazioni/ordini`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Prodotti" onClick={()=>{navigate(`/prenotazioni/prodotti`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Treni" onClick={()=>{navigate(`/prenotazioni/treni`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Servizi" onClick={()=>{navigate(`/prenotazioni/servizi`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Pacchetti" onClick={()=>{navigate(`/prenotazioni/pacchetti`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Biglietti" onClick={()=>{navigate(`/prenotazioni/biglietti`)}}/>
                </Navbar.Group>
            </Navbar>
            <Panel header={"Pacchetti Prenotati"} style={{margin:"1em"}}>
            {isLoading?
                <ProgressBar mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                :
                <DataTable value={ordini} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} tableStyle={{ minWidth: '50rem' }}
                           paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                           currentPageReportTemplate="{first} to {last} of {totalRecords}">
                    <Column field="id" header="#" style={{ width: '5%' }}></Column>
                    <Column field="codice" header="Doc." style={{ width: '5%' }}></Column>

                    <Column field="customer_display" header="Cliente" style={{ width: '25%' }}></Column>
                    <Column field="email" header="Email" style={{ width: '25%' }}></Column>

                    <Column field="importo" header="Totale" style={{ width: '10%' }}></Column>
                    <Column field="confermato" header="confermato" style={{ width: '10%' }}></Column>
                    <Column body={scontrinatoBody} header="scontrinato" style={{ width: '10%' }}></Column>

                </DataTable>
            }
            </Panel>
        </>
    )
}