import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import{ useState } from 'react';

import './App.css';

import {NavigatorApp} from "./components/navigatorapp";
import {Outlet, useNavigate} from "react-router-dom";
import {NavigatorLeft} from "./components/NavigatorLeft";
import LoginForm from "./components/LoginForm";
import {LoginFormPrime} from "./components/LoginFormPrime";
function App() {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    const handleLoginSuccess = () => {
        setIsLoggedIn(true);
    };

  // @ts-ignore
    return(<>
        {isLoggedIn? <>
                <NavigatorApp/>
                <Outlet />
                <div className={'container-fluid'}>
                    <div className={'row'}>
                        <div className={'col-9'}></div>
                    </div>
                </div>
            </>:
            <LoginForm  onLoginSuccess={handleLoginSuccess}/>
        }
    </>)

}

export default App;
