import config from "../config";

export interface TokenResponse {
    access: string;
    refresh: string;
}

export class AuthService {
    private refreshUrl: string;

    constructor(refreshUrl: string) {
        this.refreshUrl = refreshUrl;
    }

    public async refreshToken(): Promise<void> {
        const refresh = sessionStorage.getItem('refresh');

        if (!refresh) {
            throw new Error('No refresh token available');
        }

        const response = await fetch(this.refreshUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ "refresh":refresh }),
        });

        if (!response.ok) {
            throw new Error(`Error refreshing token: ${response.statusText}`);
        }

        const data: TokenResponse = await response.json();

        sessionStorage.setItem('token', data.access);
        sessionStorage.setItem('refresh', data.refresh);
    }
}
