import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import CrmApp from "./pages/Crm";
import {FattureApp} from "./pages/Fatture";
import ProdottiApp from "./pages/Prodotti";
import FinanziariaApp from "./pages/Finanziaria";
import {NavigatorApp} from "./components/navigatorapp";
import HomeApp from "./pages/Home";
import {PrenotazioniApp} from "./pages/Prenotazioni";
import {OrdiniPanel} from "./pages/prenotazioni/ordini";
import {ProdottiPanel} from "./pages/prenotazioni/prodotti";
import {TreniPanel} from "./pages/prenotazioni/treni";
import {ServiziPanel} from "./pages/prenotazioni/servizi";
import {PacchettiPanel} from "./pages/prenotazioni/pacchetti";
import {BigliettiPanel} from "./pages/prenotazioni/biglietti";
import { Logout } from './pages/Logout';
import {Manager} from "./pages/admin/manager";
import {ManagerImportTurchino} from "./pages/admin/ImportTurchino";
import {ManagerImportFerrovie} from "./pages/admin/ImportFerrovie";
import {TransazioniSumup} from "./pages/fatture/transazioni_sumup";
import {FattureEmesse} from "./pages/fatture/FattureEmesse";
import {Ordini} from "./pages/fatture/Ordini";
import {Corrispettivi} from "./pages/fatture/Corrispettivi";
import {Rimborsi} from "./pages/fatture/Rimborsi";
import {TransazioniStripe} from "./pages/fatture/TransazioniStripe";
import {RimborsiScontrini} from "./pages/fatture/RimborsiScontrini";
import {RimborsiFattura} from "./pages/fatture/RimborsiFattura";
import {RimborsiContabile} from "./pages/fatture/RimborsiContabile";
import {ProdottiTreno} from "./pages/prodotti/ProdottiTreno";
import {ProdottiTurchino} from "./pages/prodotti/ProdottiTurchino";
import {ProdottiServizi} from "./pages/prodotti/ProdottiServizi";
import {ProdottiPropManager} from "./pages/prodotti/ProdottiPropManager";
import {ProdottiTask} from "./pages/prodotti/ProdottiTask";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: '/',
        element: <App/>,
        children: [
            {
                index: true,
                element: <HomeApp />
            },
            {
                path: 'crm',
                element: <CrmApp/>
            },
            {
                path: 'fatture',
                element: <FattureApp/>
            },
            {
                path: 'prodotti',
                element: <ProdottiApp/>
            },
            {
                path: '/prodotti/treno',
                element: <ProdottiTreno/>
            },
            {
                path: '/prodotti/turchino',
                element: <ProdottiTurchino/>
            },
            {
                path: '/prodotti/servizi',
                element: <ProdottiServizi/>
            },
            {
                path: '/prodotti/propman',
                element: <ProdottiPropManager/>
            },
            {
                path: '/prodotti/tasks',
                element: <ProdottiTask/>
            },
            {
                path: 'finanza',
                element: <FinanziariaApp/>

            },
            {
                path: 'prenotazioni',
                element: <PrenotazioniApp/>,
                children : [

                ]
            },
            {
                path: '/prenotazioni/ordini',
                element: <OrdiniPanel />
            },
            {
                path: '/prenotazioni/prodotti',
                element: <ProdottiPanel />
            },
            {
                path: '/prenotazioni/treni',
                element: <TreniPanel />
            },
            {
                path: '/prenotazioni/servizi',
                element: <ServiziPanel />
            },
            {
                path: '/prenotazioni/pacchetti',
                element: <PacchettiPanel />
            },
            {
                path: '/prenotazioni/biglietti',
                element: <BigliettiPanel />
            },
            {
                path: '/logout',
                element: <Logout />
            },
            {
                path: '/manager',
                element: <Manager />
            },
            {
                path: '/manager/import_turchino',
                element: <ManagerImportTurchino />
            },
            {
                path: '/manager/import_ferrovie',
                element: <ManagerImportFerrovie />
            },
            {
                path: '/fatture/sumup',
                element: <TransazioniSumup />
            },
            {
                path: '/fatture/ordini',
                element: <Ordini />
            },
            {
                path: '/fatture/emesse',
                element: <FattureEmesse />
            },
            {
                path: '/fatture/corrispettivi',
                element: <Corrispettivi />
            },
            {
                path: '/fatture/rimborsi',
                element: <Rimborsi />
            },
            {
                path: '/fatture/stripe',
                element: <TransazioniStripe />
            },
            {
                path: '/fatture/rimborso/scontrino',
                element: <RimborsiScontrini />
            },
            {
                path: '/fatture/rimborso/fattura',
                element: <RimborsiFattura />
            },
            {
                path: '/fatture/rimborso/contabile',
                element: <RimborsiContabile />
            }
        ]

    }
])

root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
