import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Panel } from 'primereact/panel';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import React, {useEffect, useRef, useState} from 'react';
import { Button } from 'primereact/button';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import {Alignment, Button as ButtonNav, InputGroup, Navbar} from "@blueprintjs/core";
import {useNavigate} from "react-router-dom";
import {Table} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import {TabMenu} from "primereact/tabmenu";
import {InputText} from "primereact/inputtext";
import {LineItem, MetaDaum2, WCOrder} from "../../models/Woocommerce";
import { ProgressSpinner } from 'primereact/progressspinner';
import {PrenotazioneAnnullo} from "./prenotazione_annullo";
import {PrenotazioneCambio} from "./prenotazione_cambio";
import {PrenotazioneConferma} from "./prenotazione_conferma";
import {PrenotazioneEmissioneSdi} from "./prenotazione_emissione_sdi";
import {PrenotazioneRimborso} from "./prenotazione_rimborso";
import {PrenotazioneTicket} from "./prenotazione_ticket";
import {Fieldset} from "primereact/fieldset";
import {FloatLabel} from "primereact/floatlabel";
import {Accordion, AccordionTab} from "primereact/accordion";
import {Dropdown, DropdownChangeEvent} from "primereact/dropdown";
import {DeferredContent} from "primereact/deferredcontent";
import {OrderService} from "../../services/OrderService";
import config from "../../config";



export interface Ordine {
    id: number
    identity: string
    codice: any
    category: number
    qty: number
    importo: string
    confermato: boolean
    fatturato: boolean
    scontrinato: boolean
    sdi: boolean
    sdi_data: string,
    data_order: DataOrder
    wpordine: number,
    wpid:number
}

export interface DataOrder {

}


export interface RigaOrdine {
    id : number,
    code : string,
    name : string,
    category : string,
    quantity : number,

}

const rigaOrdineTable:RigaOrdine[] = [

]


const dataWP: Object= {}

interface Mese {
    name : string;
    code : number;
}

interface Anno {
    name : string;
    code : number;
}

export const OrdiniPanel = () => {
    const [, setProducts] = useState([]);
    const [dataWP,setDataWP] = useState(null)
    const [selectedOrder, setSelectedOrder] = useState<Ordine | null>(null);
    const toast = useRef(null);
    const [action,setAction] = useState(0)
    const navigate = useNavigate();

    const [dataTable,setDataTable] = useState<Ordine[]|any>([]);

    // SERACH FILEDS
    const [code,setCode] = useState('');
    const [name,setName] = useState('');
    const [category,setCategory] = useState('');
    const [qty,setQty] = useState('');
    const [fatturato,setFatturato] = useState('');
    //filtro mese/anno
    const [selanno,setSelanno] = useState<Anno | null>(null);
    const [selmese,setSelmese] = useState<Mese | null >(null);


    const anno: Anno[] = [
        {name:'Tutti', code:0},
        {name:'2020', code:2020},
        {name:'2021', code:2021},
        {name:'2022', code:2022},
        {name:'2023', code:2023},
        {name:'2024', code:2024},
        {name:'2025', code:2025},
        {name:'2026', code:2026},
        {name:'2027', code:2027},
        {name:'2028', code:2028},
        {name:'2029', code:2029},
        {name:'2030', code:2030}
    ]

    const mese: Mese[] = [
        {name:'Tutti', code:0},
        {name:'Gennaio', code:1},
        {name:'Febbraio', code:2},
        {name:'Marzo', code:3},
        {name:'Aprile', code:4},
        {name:'Maggio', code:5},
        {name:'Giugno', code:6},
        {name:'Luglio', code:7},
        {name:'Agosto', code:8},
        {name:'Settembre', code:9},
        {name:'Ottobre', code:10},
        {name:'Novembre', code:11},
        {name:'Dicembre', code:12},
    ]

    const loadData = ()=>{
        OrderService().then((ord)=>{setDataTable(ord)});
    }

    useEffect(() => {


    }, []);


    return (
        <>
            <Navbar>
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading>Prenotazioni</Navbar.Heading>
                    <Navbar.Divider />
                    <ButtonNav className="bp5-minimal" icon="document" text="Ordini" onClick={()=>{navigate(`/prenotazioni/ordini`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Prodotti" onClick={()=>{navigate(`/prenotazioni/prodotti`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Treni" onClick={()=>{navigate(`/prenotazioni/treni`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Servizi" onClick={()=>{navigate(`/prenotazioni/servizi`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Pacchetti" onClick={()=>{navigate(`/prenotazioni/pacchetti`)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Biglietti" onClick={()=>{navigate(`/prenotazioni/biglietti`)}}/>
                </Navbar.Group>
            </Navbar>
            <Panel header={"Ordini"} >
                            <Accordion activeIndex={1}>
                                <AccordionTab header={"Ricerca"}>
                                    <table style={{marginTop:"1em"}}>
                                        <tr>
                                            <td>
                                                <FloatLabel>
                                                    <InputText id="code" value={code} onChange={(e) => {
                                                        setCode(e.currentTarget.value)
                                                    }}/>
                                                    <label htmlFor={"code"}>CODE</label>
                                                </FloatLabel>
                                            </td>
                                            <td style={{paddingLeft: "1em"}}>
                                                <FloatLabel>
                                                    <InputText id="name" value={name} onChange={(e) => {
                                                        setName(e.currentTarget.value)
                                                    }}/>
                                                    <label htmlFor={"name"}>Name</label>
                                                </FloatLabel>
                                            </td>
                                            <td style={{paddingLeft: "1em"}}>
                                                <FloatLabel>
                                                    <InputText id="category" value={category} onChange={(e) => {
                                                        setCategory(e.currentTarget.value)
                                                    }}/>
                                                    <label htmlFor={"category"}>Category</label>
                                                </FloatLabel>
                                            </td>
                                            <td style={{paddingLeft: "1em"}}>
                                                <FloatLabel>
                                                    <InputText id="qty" value={qty} onChange={(e) => {
                                                        setQty(e.currentTarget.value)
                                                    }}/>
                                                    <label htmlFor={"qty"}> QTY </label>
                                                </FloatLabel>
                                            </td>
                                            <td style={{paddingLeft: "1em"}}>
                                                <FloatLabel>
                                                    <InputText id="fattura" value={fatturato} onChange={(e) => {
                                                        setFatturato(e.currentTarget.value)
                                                    }}/>
                                                    <label htmlFor={"fattura"}>Fatturato</label>
                                                </FloatLabel>
                                            </td>
                                            <td style={{paddingLeft: "1em"}}>
                                                <Dropdown value={selmese}
                                                          onChange={(e: DropdownChangeEvent) => setSelmese(e.target.value)}
                                                          options={mese} optionLabel="Mese"
                                                          placeholder={"Filtro Mese"}/>
                                            </td>
                                            <td style={{paddingLeft: "1em"}}>
                                                <Dropdown value={selanno}
                                                          onChange={(e: DropdownChangeEvent) => setSelanno(e.value)}
                                                          options={anno} optionLabel="Anno"
                                                          placeholder={"Filtro Anno"}/>
                                            </td>
                                            <td style={{paddingLeft: "1em"}}>
                                                <Button label={"Cerca"} onClick={() => {
                                                    alert(`ricerca dati ${selmese?.code}`)
                                                }}/>
                                            </td>
                                        </tr>
                                    </table>


                                </AccordionTab>
                                <AccordionTab header={"Dati"}>
                                    <DeferredContent onLoad={loadData}>
                                    <DataTable value={dataTable} selectionMode="single" showGridlines={true}
                                               tableStyle={{minWidth: '70rem'}} selection={selectedOrder}
                                               onSelectionChange={(e) => { setSelectedOrder(null);setSelectedOrder(e.value as Ordine);}} dataKey="id">
                                        <Column field="id" header="#"></Column>
                                        <Column field="codice" header="Ref"></Column>
                                        <Column field="identity" header="Codice"></Column>
                                        <Column field="customer_display" header="Cliente"></Column>
                                        <Column field="category" header="Tipo"></Column>
                                        <Column field="quantity" header="Quantita"></Column>
                                        <Column field="importo" header="Importo"></Column>
                                    </DataTable>
                                    </DeferredContent>
                                </AccordionTab>
                            </Accordion>


            </Panel>
            {selectedOrder ?
                <>
            <Navbar style={{marginTop:"3em"}}>
                <Navbar.Group align={Alignment.RIGHT}>
                    <ButtonNav className="bp5-minimal" icon="record" text="Dati Ordine" onClick={()=>{setAction(0)}}/>
                    <ButtonNav className="bp5-minimal" icon="confirm" text="Conferma" onClick={()=>{setAction(4)}}/>
                    <ButtonNav className="bp5-minimal" icon="issue-new" text="Apri Ticket" onClick={()=>{setAction(7)}}/>
                    <ButtonNav className="bp5-minimal" icon="changes" text="Cambio Posti" onClick={()=>{setAction(3)}}/>
                    <ButtonNav className="bp5-minimal" icon="credit-card" text="Rimborso" onClick={()=>{setAction(6)}}/>
                    <ButtonNav className="bp5-minimal" icon="delete" text="Annulla Ordine" onClick={()=>{setAction(2)}}/>
                    <ButtonNav className="bp5-minimal" icon="document" text="Emetti Fattura" onClick={()=>{setAction(5)}}/>
                </Navbar.Group>
            </Navbar>
            <Panel header={selectedOrder?.codice}>
                {action == 0?<OrderView {...selectedOrder} />:null}
                {action == 2?<PrenotazioneAnnullo numero={Number(selectedOrder?.importo)} fattura={'1/F'} idordine={selectedOrder.wpordine} />:null}
                {action == 3?<PrenotazioneCambio  />:null}
                {action == 4?<PrenotazioneConferma  />:null}
                {action == 5?<PrenotazioneEmissioneSdi  />:null}
                {action == 6?<PrenotazioneRimborso  />:null}
                {action == 7?<PrenotazioneTicket  />:null}
            </Panel>
                </>
                : null }

        </>
    )

}

interface OrderView {
    ordine : Ordine
}

const OrderView = (props:Ordine) =>{
    const [dataOrder,setdataOrder] = useState(null);

    useEffect(() => {
        alert(props.codice)
        if(props.codice.startsWith('T')){
            alert("Fattura Trabocco")
        } else {
            alert('Fattura Ferrovie')
        }
        //fetch(`https://fdp.netsons.org/wp-json/wc/v3/orders/${props.wpid}?consumer_key=ck_c81fa08b14a09ba53f590f2d87e631ff22fe56c6&consumer_secret=cs_976f9a90b91dfbb90fae993fcc578c38a0964d11`)
        let url_fetch = `https://fdp.netsons.org/wp-json/wc/v3/orders/${props.wpid}?consumer_key=ck_c81fa08b14a09ba53f590f2d87e631ff22fe56c6&consumer_secret=cs_976f9a90b91dfbb90fae993fcc578c38a0964d11`
        if(props.category==2){
            url_fetch =  `https://www.traboccoturchino.com//wp-json/wc/v3/orders/${props.wpid}?consumer_key=ck_4e412919960d1297db91c67b9fa86bdcb7f0967b&consumer_secret=cs_3e6fc8311395fb6146cd9d31ab18717952d2805b`
        }
        console.log(url_fetch);
        fetch(url_fetch, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + sessionStorage.getItem('token')
            },
        })
            .then((data)=>{return data.json()}).then((resp)=>{
            setdataOrder(resp);
            console.log(resp)
        })
    },[]);
    const [activeIndex, setActiveIndex] = useState(3);
    const items = [
        { label: 'Dati Ordine', icon: 'pi pi-home' },
        { label: 'Prodotti', icon: 'pi pi-chart-line' },
        { label: 'Pagamenti', icon: 'pi pi-list' },
        { label: 'Note', icon: 'pi pi-inbox' }
    ];
    return (

                <>
                    <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>
                        {activeIndex == 0 ? <DatiOrdine {...props}  />: null}
                        {activeIndex == 1 ? <DatiProdotti {...props} />: null}
                        {activeIndex == 2 ? <DatiPagamenti {...props} />: null}
                        {activeIndex == 3 ? <DatiNote {...props} />: null}
                    </>
    )
}

interface OrdineWC {
    wpid:number,
}

const DatiOrdine = (props:Ordine) => {
    // @ts-ignore
    //let wc:WCOrder = null;
    const [wc,setWc] = useState<WCOrder|any>(null)
    const[loaded,setLoaded] = useState(false)
    //const datiOrdineTable:Ordine[] = [{...props}]
    useEffect(() => {
        let url_fetch = `https://fdp.netsons.org/wp-json/wc/v3/orders/${props.wpid}?consumer_key=ck_c81fa08b14a09ba53f590f2d87e631ff22fe56c6&consumer_secret=cs_976f9a90b91dfbb90fae993fcc578c38a0964d11`
        if(props.category==2){
            url_fetch =  `https://www.traboccoturchino.com//wp-json/wc/v3/orders/${props.wpid}?consumer_key=ck_4e412919960d1297db91c67b9fa86bdcb7f0967b&consumer_secret=cs_3e6fc8311395fb6146cd9d31ab18717952d2805b`
        }
        fetch(url_fetch)
            .then((data)=>{return data.json()}).then((resp)=>{
            let dati = resp as WCOrder;
            console.log("=================")
            setWc(dati)
            console.log(wc)
            setLoaded(true)
        })
    },[]);

    // @ts-ignore
    return (
        <>
            {loaded ?<>
                <Table  className="table table-striped table-bordered">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Ref</th>
                        <th>Cliente</th>
                        <th>Indirizzo</th>
                        <th>Email</th>
                        <th>Telefono</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>1</td>
                        <td>{wc?.id}</td>
                        <td>{wc?.billing?.first_name + ' ' + wc?.billing?.last_name}</td>
                        <td>{wc?.billing?.address_1 }</td>
                        <td>{wc?.billing?.email}</td>
                        <td>{wc?.billing?.phone}</td>
                    </tr>
                    </tbody>
                </Table>
            </>:<ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> }
        </>
    )
}

const DatiProdotti = (props:Ordine) => {
    const [wc,setWc] = useState({} as WCOrder)
    const[loaded,setLoaded] = useState(false)
    //const datiOrdineTable:Ordine[] = [{...props}]
    useEffect(() => {

        fetch(`https://fdp.netsons.org/wp-json/wc/v3/orders/${props.wpid}?consumer_key=ck_c81fa08b14a09ba53f590f2d87e631ff22fe56c6&consumer_secret=cs_976f9a90b91dfbb90fae993fcc578c38a0964d11`)
            .then((data)=>{return data.json()}).then((resp)=>{
            let dati = resp as WCOrder;
            setWc(dati)
            setLoaded(true)
            console.log(dati.line_items)
            dati.line_items.map((line) =>{
                console.log(line.name)
            })

        })
    },[]);
    return (
        <>
            {loaded ? <>
                <h4>Prodotti</h4>
                {
                    wc.line_items.map((line_item: LineItem) => {
                        let meta:MetaDaum2[] = line_item?.meta_data
                        return (
                            <Table className="table table-striped table-bordered">
                                <thead>
                                <tr>
                                    <th>PID</th>
                                    <th>Prodotto</th>
                                    <th>QTY</th>
                                    <th>Subtotale</th>
                                    <th>Tasse</th>
                                    <th>Totale</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{line_item?.product_id}</td>
                                    <td>{line_item?.name}</td>
                                    <td>{line_item?.quantity}</td>
                                    <td>{line_item?.subtotal}</td>
                                    <td>{line_item?.subtotal_tax}</td>
                                    <td>{line_item?.total}</td>

                                </tr>
                                </tbody>
                            </Table>
                        )
                    })
                }
                <h4 style={{marginTop:"2em"}}>Prenotazioni</h4>
                <Table className="table table-striped table-bordered">
                    <thead>
                    <tr>
                        <th>Prodotto</th>
                        <th>Data</th>
                        <th>Tipo</th>
                        <th>Selezione</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        wc.line_items.map((line_item: LineItem) => {

                        let meta: MetaDaum2[] = line_item?.meta_data
                        return (<tr>
                            <td>{line_item?.name}</td>
                            {meta?.map((meta_data: MetaDaum2) => {
                                return (
                                    <>

                                        {meta_data.key == 'selected_date' ? <td>{meta_data.value}</td> : null}
                                        {meta_data.key == 'type' ? <td>{meta_data.value}</td> : null}
                                        {meta_data.key == 'selected_slots' ? <td>{meta_data.value}</td> : null}
                                    </>
                                )
                            })
                            }
                        </tr>)
                        })
                    }
                    </tbody>
                </Table>

            </> : <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> }
        </>
    )
}

const DatiPagamenti = (props:Ordine) => {
    const [wc,setWc] = useState({} as WCOrder)
    const[loaded,setLoaded] = useState(false)
    //const datiOrdineTable:Ordine[] = [{...props}]
    useEffect(() => {
        let url_fetch = `https://fdp.netsons.org/wp-json/wc/v3/orders/${props.wpid}?consumer_key=ck_c81fa08b14a09ba53f590f2d87e631ff22fe56c6&consumer_secret=cs_976f9a90b91dfbb90fae993fcc578c38a0964d11`
        if(props.category==2){
            url_fetch =  `https://www.traboccoturchino.com//wp-json/wc/v3/orders/${props.wpid}?consumer_key=ck_4e412919960d1297db91c67b9fa86bdcb7f0967b&consumer_secret=cs_3e6fc8311395fb6146cd9d31ab18717952d2805b`
        }
        fetch(url_fetch)
            .then((data)=>{return data.json()}).then((resp)=>{
            let dati = resp as WCOrder;
            console.log("=================")
            setWc(dati)
            console.log(wc)
            setLoaded(true)
        })
    },[]);
    return (
        <>
            {loaded ? <>
                <Table  className="table table-striped table-bordered">
                    <thead>
                    <tr>
                        <th>Metodo Pagamento</th>
                        <th>Currency</th>
                        <th>Totale</th>
                        <th>Data Pagamento</th>
                        <th>Transaction</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{wc?.payment_method} - {wc?.payment_method_title}</td>
                        <td>{wc?.currency}</td>
                        <td>{wc?.total}</td>
                        <td>{wc?.date_paid}</td>
                        <td>{wc?.transaction_id}</td>
                    </tr>
                    </tbody>
                </Table>

                <Table style={{marginTop:"2em"}} className="table table-striped table-bordered">
                    <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Numero</th>
                        <th>Emissione</th>
                        <th>Data SDI</th>
                        <th>Importo</th>
                        <th>IVA</th>
                    </tr>
                    </thead>
                    <tbody>

                    </tbody>
                </Table>
            </> : <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> }
        </>
    )
}

const DatiNote = (props:Ordine) => {
    const [wc,setWc] = useState({} as WCOrder)
    const[loaded,setLoaded] = useState(false)
    //const datiOrdineTable:Ordine[] = [{...props}]
    useEffect(() => {
        let url_fetch = `https://fdp.netsons.org/wp-json/wc/v3/orders/${props.wpid}?consumer_key=ck_c81fa08b14a09ba53f590f2d87e631ff22fe56c6&consumer_secret=cs_976f9a90b91dfbb90fae993fcc578c38a0964d11`
        if(props.category==2){
            url_fetch =  `https://www.traboccoturchino.com/wp-json/wc/v3/orders/${props.wpid}?consumer_key=ck_4e412919960d1297db91c67b9fa86bdcb7f0967b&consumer_secret=cs_3e6fc8311395fb6146cd9d31ab18717952d2805b`
        }
        fetch(url_fetch)
            .then((data)=>{return data.json()}).then((resp)=>{
            let dati = resp as WCOrder;
            console.log("=================")
            setWc(dati)
            console.log(wc)
            setLoaded(true)
        })
    },[]);
    return (
        <>
            {loaded ? <>
                <h4>Note Ordine</h4>
                <Table className="table table-striped table-bordered">
                    <tr>
                        <th>customer_ip_address</th>
                        <td>{wc?.customer_ip_address}</td>
                    </tr>
                    <tr>
                        <th>customer_note</th>
                        <td>{wc?.customer_note}</td>
                    </tr>
                    <tr>
                        <th>cart_hash</th>
                        <td>{wc?.cart_hash}</td>
                    </tr>
                    <tr>
                        <th>needs_processing</th>
                        <td>{wc?.needs_processing}</td>
                    </tr>
                    <tr>
                        <th>needs_payment</th>
                        <td>{wc?.needs_payment}</td>
                    </tr>
                    <tr>
                        <th>date_completed_gmt</th>
                        <td>{wc?.date_completed_gmt}</td>
                    </tr>
                </Table>
            </> : <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="var(--surface-ground)"
                                   animationDuration=".5s" /> }
        </>
    )
}