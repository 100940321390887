import {
    Alignment, AnchorButton,
    Button,
    Classes,
    H5,
    Navbar,
    NavbarDivider,
    NavbarGroup,
    NavbarHeading,
    Switch,
} from "@blueprintjs/core";
import { Breadcrumbs, Breadcrumb, BreadcrumbProps, Icon } from "@blueprintjs/core";

import { Card, Menu, MenuDivider, MenuItem, Popover } from "@blueprintjs/core";

import React from "react";

import {Link, NavLinkProps, useNavigate} from "react-router-dom";

export const NavigatorApp = () => {

    const navigate = useNavigate();

    const BREADCRUMBS: BreadcrumbProps[] = [];

     const userMenu = (
         <Menu>
             <MenuItem icon="property" text="My Account" />
             <MenuItem icon="log-out" text="Logout" />
         </Menu>
     );


     return (

         <>
            <Navbar className={"navbar-dark bp5-dark"}>
                <Navbar.Group align={Alignment.LEFT}>
                    <Navbar.Heading>SysBooking<sup>2</sup></Navbar.Heading>
                    <Navbar.Divider />
                    <Button className="bp5-minimal" icon="home" text="Home" onClick={()=>{navigate(`/`)}}/>
                    <Button className="bp5-minimal" icon="book" text="Prenotazioni" onClick={()=>{navigate(`prenotazioni`)}}/>
                    <Button className="bp5-minimal" icon="box" text="Prodotti" onClick={()=>{navigate(`prodotti`)}}/>
                    <Button className="bp5-minimal" icon="envelope" text="CRM" onClick={()=>{navigate(`crm`)}}/>
                    <Button className="bp5-minimal" icon="manual" text="Fatture" onClick={()=>{navigate(`fatture`)}}/>
                    <Button className="bp5-minimal" icon="manual" text="Finanza Aziendale" onClick={()=>{navigate(`finanza`)}}/>
                </Navbar.Group>
                <Navbar.Group align={Alignment.RIGHT}>
                    <Button className="bp5-minimal" icon="console" text="Manager" onClick={()=>{navigate(`manager`)}}/>
                    <Navbar.Divider />
                    <Popover content={userMenu} fill={true} placement="bottom">
                        <Button className="bp5-minimal" icon="user" text="b.bentenuto" />
                    </Popover>
                </Navbar.Group>
            </Navbar>
         </>
        )
 }

